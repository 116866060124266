Vue.component('invoice-data-form', function(resolve){
    require(['./InvoiceDataForm.vue'], resolve);
});

Vue.component('postal-code-input', function(resolve){
    require(['./PostalCodeInput.vue'], resolve);
});

Vue.component('loader', function(resolve){
    require(['./Loader.vue'], resolve);
});

Vue.component('leaflet-map', function(resolve){
    require(['./LeafletMap.vue'], resolve);
});

//Profile tab in edit profile page
Vue.component('profile-edit', function(resolve){
    require(['./ProfileEdit.vue'], resolve);
});

Vue.component('prop-search-form', function(resolve){
    require(['./PropSearchForm.vue'], resolve);
});

Vue.component('property-details-list', function(resolve){
    require(['./PropertyDetailsToList.vue'], resolve);
});

Vue.component('property-card-list', function(resolve){
    require(['./PropertyCardList.vue'], resolve);
});

Vue.component('pagination', function(resolve){
    require(['./Pagination.vue'], resolve);
});

Vue.component('show-phone-number', function(resolve){
    require(['./ShowPhoneNumber.vue'], resolve);
});

Vue.component('highlight-datatable', function(resolve){
    require(['./HighlightDatatable.vue'], resolve);
});
