import {isString, isEqual, debounce, flatten, toArray, isEmpty} from "lodash";
import {isUndefinedOrNull, isObject} from "tupy-helperjs";

const hasProperty = (obj, prop) => {
    if (isUndefinedOrNull(obj)) {
        return false;
    }

    return Object.prototype.hasOwnProperty.call(obj, prop) && !isUndefinedOrNull(obj[prop]);
}

const extractErrors = (errors, messageDefault = "Não foi possível atender o pedido") => {
    let formatted;

    if (errors && hasProperty(errors, "response") && hasProperty(errors.response, "data")) {
        if (isObject(errors.response.data.errors)) {
            formatted = flatten(toArray(errors.response.data.errors));
        } else if (isObject(errors.response.data)) {
            formatted = flatten(toArray(errors.response.data));
        } else if (hasProperty(errors.response.data, "message")) {
            formatted = [errors.response.data.message];
        } else {
            formatted = [errors.response.data];
        }
    } else if (errors && hasProperty(errors, "responseJSON")) {
        if (isObject(errors.responseJSON.errors)) {
            formatted = flatten(toArray(errors.responseJSON.errors));
        } else if (isObject(errors.responseJSON)) {
            formatted = flatten(toArray(errors.responseJSON));
        } else if (hasProperty(errors.responseJSON, "message")) {
            formatted = [errors.responseJSON.message];
        } else {
            formatted = [errors.responseJSON];
        }
    } else if (errors && hasProperty(errors, "message")) {
        formatted = [errors.message];
    } else if (errors && hasProperty(errors, "responseText")) {
        formatted = [errors.responseText];
    } else {
        console.debug('formatterErrors', errors);
        formatted = [messageDefault];
    }

    return formatted;
};

const toCurrency = (value = 0, currency = 'EUR') => {
    return value ? parseFloat(String(value)).toLocaleString("pt-BR", { style: "currency", currency }) : 0
}

export {hasProperty, extractErrors, toCurrency};

window.HelperJS = {
    isEqual,
    debounce,
    isEmpty,
    /**
     * @param str
     * @returns {string}
     * @link https://stackoverflow.com/a/5782563/12360583
     */
    slug(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        let from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
        let to = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";

        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    },

    routeSlugAgency({ami, company_name}) {
        return route('companies.redirectBySlug', this.slug(`${company_name || 'at'}-${ami}`));
    },

    replaceUrlToCdn(url, cdn = 'https://ccerta.fra1.cdn.digitaloceanspaces.com') {
        if (!isString(url)) {
            return url;
        }

        return url.replace('https://fra1.digitaloceanspaces.com/ccerta', cdn);
    },
    extractErrors,
    hasProperty,
    toCurrency,
    removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '');
    },
    /**
     * Make default config of datatable
     * @param config {DataTables.Settings}
     * @return {DataTables.Settings}
     */
    commonConfigDatatable(config = {}) {
        return Object.assign({}, {
            processing: true,
            serverSide: true,
            searchDelay: 1000,
            responsive: true,
            pageLength: 10,
            colReorder: true,
            paging: true,
            info: true,
            language: {
                //sProcessing: "<span> <img style='width: 65px;' src='/images/loaderBalls.svg'> Loading... </span>",
                sLengthMenu: " _MENU_ registos por página",
                sZeroRecords: "Sem Resultados",
                sInfo: "<span class='datatables_counter_resume s-info-page'>A mostrar de _START_ a _END_ | Total:  _TOTAL_ registos </span>",
                sInfoEmpty: "<span  class='datatables_counter_resume'></span>0 registos",
                sInfoFiltered: "<span class='datatables_counter_resume'>(filtrado de _MAX_ total)</span>",
                //"sInfoPostFix: "",
                sSearch: "<span class='font-normal'> <i class='fa fa-search lightblue'></i></span>",
                searchPlaceholder: "Search",
                sUrl: "",
                oPaginate: {
                    sFirst: "Primeiro",
                    sPrevious: '<img src="/icons/chevron-left.svg" alt="Previous" height="16" width="16">',
                    sNext: '<img src="/icons/chevron-right.svg" alt="Next" height="16" width="16">',
                    sLast: "Último"
                }
            },
            stateSave: false,
            //ONLY SHOWS DATATABLES PAGINATION IF HAS MORE THAN ONE PAGE
            fnDrawCallback: function (oSettings) {
                if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
                    $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
                } else {
                    $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
                }
            },
            order: [[0, 'desc']],
        }, config);
    },

    legislationForPhoneN(string) {
        //Function in javascript
        // IF YOU CHANGE THIS FUNCTION, CHANGE IT ALSO IN PHP
        // GlobalHelper.php

        let newString=string.replace('+351 ', '');
        newString=newString.replace('(+351) ', '');
        newString=newString.replace('+351', '');
        if (newString.substring(0, 1) === '9') {
            return '<span style="font-size: 8pt;  display:block;">Chamada para a rede móvel nacional</span>';
        }else if(
            newString.substring(0, 1) === '2'
            || newString.substring(0, 1) === '3'
        ){
            return '<span style="font-size: 8pt;  display:block;">Chamada para a rede fixa nacional</span>';

        }else if(
            newString.substring(0, 3) === '800'
        ){
            return '<span style="font-size: 8pt;  display:block;">Chamada grátis nacional</span>';
        }else if(
            newString.substring(0, 3) === '808'
        ){
            return '<span style="font-size: 8pt;  display:block;">Chamada com custo local</span>';
        }else if(
            newString.substring(0, 2) === '70'
        ){
            return '<span style="font-size: 8pt;  display:block;">Chamada com valor acrescentado</span>';
        }
    }
}
