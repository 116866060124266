//Alterei aqui para que possa desabilitar o debug do vue quando roda o prod
// import Vue from "vue/dist/vue.js"
import Vue from "vue"
window.Vue = Vue;
import * as Sentry from "@sentry/vue";

Sentry.init({
    Vue,
    dsn: "https://30c16d7c98d8098f69ca1742173b2efd@o485717.ingest.us.sentry.io/4507622443253760",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    trackComponents: true,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  /*  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],*/

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.3,
});

import Multiselect from 'vue-multiselect'
Vue.component('Multiselect', Multiselect);
Multiselect.props.selectedLabel.default = 'Selecionado';
Multiselect.props.selectLabel.default = 'Clique para selecionar';
Multiselect.props.deselectLabel.default = 'Clique para remover';
Multiselect.props.selectGroupLabel.default = 'Clique para selecionar o grupo';
Multiselect.props.deselectGroupLabel.default = 'Clique para remover o grupo';

Vue.prototype.$http = axios;
Vue.config.performance = true;
Vue.config.runtimeCompiler = true;

require('./components/index.js');

Vue.mixin({
    methods:{
        vueRoute(...args){
            return route(...args);
        },

        formatPrice(value){
            function stringReverse(string){
                var str = ''
                for(const letter of string){
                    str = letter + str;
                }
                return str;
            }

            function chunk(str, n) {
                var ret = [];
                var i;
                var len;

                for(i = 0, len = str.length; i < len; i += n) {
                    ret.push(str.substr(i, n))
                }

                return ret
            }

            return stringReverse(chunk(stringReverse(value.toString()), 3).join('.'));
        },

        asset(path) {
            var base_path = window._asset || '';
            return base_path + path;
        },

        initializeVariables(){
            const el = document.getElementById('data-page')
            if (el && el.value) {
                Object.assign(this.$data, JSON.parse(el.value))
                setTimeout(() => {
                    el.remove()
                }, 100);
            }
        },

        async delaySearch(delay, search){
            if(this.mainInterval){
                clearTimeout(this.mainInterval);
            }

            this.mainInterval = setTimeout(async () => {
                await search();
                this.mainInterval = undefined;
            }, delay)
        },
        route(name, ...args){
            return route(name, args);
        },

        replaceImageOnError($ev, type = 'user'){
            if (type === 'user') {
                $ev.target.src = this.asset('images/noImageUserMan.svg');
            }

            if (type === 'logo') {
                $ev.target.src = this.asset('images/noLogo.svg');
            }
        },

        cdnUrl(url) {
            return HelperJS.replaceUrlToCdn(url)
        },
    }
})
