import index from "@/routes/base-routes.js";

window.route = function() {
    let args = Array.prototype.slice.call(arguments);
    let name = args.shift();
    let origin = '';

    if (window.location.origin) {
        origin = window.location.origin;
    }

    if (index[name] === undefined) {
        console.error('Unknown route ', name);
    } else {
        return origin + '/' + index[name]
            .split('/')
            .map(s => s[0] === '{' ? args.shift() : s)
            .join('/');
    }
};

window.url = (path) => {
    if (path === undefined) {
        return window.location.origin;
    }

    let concat = '/';

    if (path[0] === '/') {
        concat = '';
    }

    return window.location.origin + concat + path
};