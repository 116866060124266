
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./packages/sweetAlert2.js');

$(document).ready(function(){
    $('.collapsable-section-header .collapsable-trigger').click(function(){
        $(this).closest('.collapsable-section-wrapper').find('.collapsable-trigger').toggleClass('trigger-open');
        $(this).closest('.collapsable-section-wrapper').find('.collapsable-section-content').toggleClass('collapsable-effect');
        window.setTimeout(function() {
            $('#logindata .fa-times-circle').addClass('fa-check-circle').removeClass('fa-times-circle');
        }, 1000);
    });

    $('.tabDisable *').on('keydown', function(e){
        if (e.keyCode == 9) {
            e.preventDefault();
        }
    });

    //Trecho que estava no layout
    let cHeight = 0;

    $('.carousel').on('slide.bs.carousel', function (e) {
        let $nextImage = null;

        let $activeItem = $('.active.carousel-item', this);

        if (e.direction == 'left'){
            $nextImage = $activeItem.next('.carousel-item').find('img');
        } else {
            if ($activeItem.index() == 0){
                $nextImage = $('img:last', $activeItem.parent());
            } else {
                $nextImage = $activeItem.prev('.carousel-item').find('img');
            }
        }

        // prevents the slide decrease in height
        if (cHeight == 0) {
            cHeight = $(this).height();
            $activeItem.next('.carousel-item').height(cHeight);
        }

        // prevents the loaded image if it is already loaded
        let src = $nextImage.data('lazy-load-src');

        if (typeof src !== "undefined" && src !== "") {
            $nextImage.attr('src', src)
            $nextImage.data('lazy-load-src', '');
        }
    });
    //Fim do trecho que estava no layout
});

// existem 2 modals que nao tem botao de fechar e foi pedido para fecharem no back button do android
if (window.history && window.history.pushState) {
    const url_data = new URL(window.location);
    $('#target-props .modal').on('show.bs.modal', function (e) {
        window.history.pushState('forward', null, url_data+'/#modal');
    });

    $(window).on('popstate', function () {
        $('#target-props .modal').modal('hide');
    });
}

require('./helper.js');
require('./routes/index.js');

require('./vue/index.js');

//Removi o include para reduzir o tamanho do ficheiro (mesmo depois de minificado)
//Nessa caso, coloquei no ficheiro de configuração do webpack.mix.js e chamei o ficheiro minificado na página
// require('./pages/admin/adminGiveCredits.js');
// require('./pages/user/userTransferCredits.js');

